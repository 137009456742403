import BlockRender from '@src/blocks';
import { Layout } from '@src/components/Layout';
import { IPageBlocks } from '@src/models/IPageBlocks';
import ILayoutConfig from '@src/models/LayoutConfig.model';
import { Grid } from '@src/components/Grid';
import React, { FC } from 'react';
import get from 'lodash/get';
import { createBackgroundImageConfig } from '@src/utils/createBackgroundImageConfig';
import { getDataFellowBlocks } from '@src/utils/getDataFellowBlocks';
import { getDataBlogItems } from '@src/utils/getDataBlogItems';
import { getNextPrevNewsData } from '@src/utils/getNextPrevNewsData';
import { getDataLatestNews } from '@src/utils/getDataLatestNews';
import { getAllPaths } from '@src/api-service/getAllPaths';
import { getContentItemByRoute } from '@src/api-service/cms/get-content-item-by-route';
import { isContentSegmentUrl } from '@src/utils/is-content-segment-url-match';

interface PageProps {
  layoutConfig: any;
  blockConfig: IPageBlocks;
  timeBuild: number;
}

const BasicPage: FC<PageProps> = ({ layoutConfig, blockConfig, timeBuild }) => {
  return (
    <Layout
      layoutConfig={layoutConfig}
      blockConfig={blockConfig}
      timeBuild={timeBuild}
    >
      <Grid size="l">
        {blockConfig?.fields?.blocks?.map((block, blockIndex) => (
          <BlockRender
            block={block}
            key={`${block.system.contentType}-${blockIndex}`}
          />
        ))}
      </Grid>
    </Layout>
  );
};

export default BasicPage;

export async function getStaticProps(context) {
  const pageParams = context.params.page;
  let pageBlocksData: IPageBlocks;
  const layoutConfig: ILayoutConfig = await getContentItemByRoute('');
  const hamburgerMenus = get(layoutConfig, 'fields.hamburgerMenus', []) || [];
  const pageUrl = pageParams.join('/');
  const currentMainPageUrl = '/' + pageUrl;
  pageBlocksData = await getContentItemByRoute(pageUrl);
  hamburgerMenus.forEach(menuItem => {
    const menu = get(menuItem, 'fields.menu', []) || [];
    menu.forEach((menu, menuIndex) => {
      menu.isActive = isContentSegmentUrl(
        menu.contentSegmentUrl,
        currentMainPageUrl,
      );
      if (menuIndex && menu.isActive) {
        menuItem.fields.menu[0].isActive = true;
      }
    });
  });

  await getDataFellowBlocks(pageBlocksData);

  await getDataBlogItems(pageBlocksData);

  await getDataLatestNews(pageBlocksData);

  await getNextPrevNewsData(pageBlocksData, pageUrl);

  createBackgroundImageConfig(pageBlocksData);

  return {
    props: {
      layoutConfig: layoutConfig,
      blockConfig: pageBlocksData,
      timeBuild: new Date().getTime(),
    },
  };
}

export async function getStaticPaths() {
  const paths = await getAllPaths();

  return {
    paths: paths,
    fallback: true,
  };
}
